package com.ecosave.watch.portal.components.esg

import com.ecosave.watch.portal.models.common.NotificationState
import com.ecosave.watch.portal.components.common.DialogSpinner
import com.ecosave.watch.portal.helpers.common.OperationType
import com.ecosave.watch.portal.helpers.common.handleNotificationStatus
import com.ecosave.watch.portal.helpers.esg.EsgSection
import com.ecosave.watch.portal.helpers.esg.esgPatch
import com.ecosave.watch.portal.helpers.mainScope
import com.ecosave.watch.portal.models.esg.AntiCompetitiveBehaviorState
import com.ecosave.watch.portal.models.esg.EsgReportState
import com.ecosave.watch.portal.services.esg.esgDownloadReportSection
import kotlinx.coroutines.launch
import kotlinx.serialization.json.JsonPrimitive
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.FormControlVariant
import mui.material.TextField
import mui.system.Box
import mui.system.sx
import react.FC
import react.Props
import react.StateSetter
import react.dom.html.ReactHTML.label
import react.dom.onChange
import react.useState
import web.cssom.px
import web.html.HTMLInputElement
import web.html.InputType
import com.ecosave.watch.portal.styles.esg.ESGCommonStyles as styles

external interface AntiCompetitiveBehaviorComponentProps : Props {
    var esgReportStateProps: EsgReportState
    var esgReportStateSetterProps: StateSetter<EsgReportState>
}

val AntiCompetitiveBehaviorComponent = FC<AntiCompetitiveBehaviorComponentProps> { props ->
    val esgReportState = props.esgReportStateProps
    var isLoading by useState(false)
    val (notificationState, notificationStateSetter) = useState(NotificationState())

    esgReportState.report?.antiCompetitiveBehavior?.let { antiCompetitiveBehaviorState ->

        val esgReportStateSetter = props.esgReportStateSetterProps

        fun setAntiCompetitiveBehaviorState(antiCompetitiveBehaviorState: AntiCompetitiveBehaviorState) {
            esgReportStateSetter(
                esgReportState.copy(
                    report = esgReportState.report.copy(
                        antiCompetitiveBehavior = antiCompetitiveBehaviorState
                    )
                )
            )
        }

        Box {
            className = styles.MAIN_ESG_FORM.cssClass
            Box {
                Button {
                    className = styles.ESG_PREVIEW.cssClass
                    variant = ButtonVariant.contained
                    onClick = {
                        isLoading = true
                        mainScope.launch {
                            val status = esgDownloadReportSection(
                                props.esgReportStateProps.reportFileName,
                                EsgSection.ANTI_COMPETITIVE_BEHAVIOR
                            )
                            isLoading = false
                            handleNotificationStatus(status, notificationState, notificationStateSetter, OperationType.DOWNLOAD)

                        }
                    }
                    +"Preview"
                }
            }
            Box {
                label {
                    className = styles.ESG_FIRST_FONT.cssClass
                    +"Anti-Competitive Behaviour"
                }
            }
            Box {
                label {
                    className = styles.ESG_SECOND_FONT.cssClass
                    +"GRI 206-1 Legal actions for anti-competitive behavior, anti-trust, and monopoly practices"

                }
            }
            Box {
                sx {
                    marginBottom = 20.px
                }
                label {
                    className = styles.ESG_FOURTH_FONT.cssClass
                    +"Number of legal actions pending or completed during the reporting period regarding anti-competitive behavior and violations of anti-trust and monopoly legislation in which the organization has been identified as participant"
                }
                // make decision if we want to use Int data types or string for numbers as toIntOrNull might give null if it's a big number
                TextField {
                    className = styles.ESG_INPUT_ITEM.cssClass
                    type = InputType.number
                    variant = FormControlVariant.outlined
                    value = antiCompetitiveBehaviorState.section2061Number
                    onChange = {
                        val target = it.target as HTMLInputElement
                        setAntiCompetitiveBehaviorState(
                            antiCompetitiveBehaviorState.copy(
                                section2061Number = target.value.toIntOrNull()
                            )
                        )

                        esgPatch(
                            "section2061Number",
                            JsonPrimitive(target.value.toInt()),
                            EsgSection.ANTI_COMPETITIVE_BEHAVIOR.name,
                            esgReportState.reportFileName
                        )
                    }
                }
            }
            Box {
                className = styles.ESG_TWO_COLUMNS.cssClass
                Box {
                    label {
                        className = styles.ESG_FOURTH_FONT_COLOR.cssClass
                        +"Completed legal actions"
                    }
                }
                Box {
                    label {
                        className = styles.ESG_FOURTH_FONT_COLOR.cssClass
                        +"Main outcomes, including any decisions or judgments"
                    }
                }
                TextField {
                    className = styles.ESG_INPUT_ITEM.cssClass
                    type = InputType.text
                    variant = FormControlVariant.outlined
                    value = antiCompetitiveBehaviorState.section2061Completed1
                    onChange = {
                        val target = it.target as HTMLInputElement
                        setAntiCompetitiveBehaviorState(
                            antiCompetitiveBehaviorState.copy(
                                section2061Completed1 = target.value
                            )
                        )

                        esgPatch(
                            "section2061Completed1",
                            JsonPrimitive(target.value),
                            EsgSection.ANTI_COMPETITIVE_BEHAVIOR.name,
                            esgReportState.reportFileName
                        )
                    }
                }
                TextField {
                    className = styles.ESG_INPUT_ITEM.cssClass
                    type = InputType.text
                    variant = FormControlVariant.outlined
                    value = antiCompetitiveBehaviorState.section2061Outcome1
                    onChange = {
                        val target = it.target as HTMLInputElement
                        setAntiCompetitiveBehaviorState(
                            antiCompetitiveBehaviorState.copy(
                                section2061Outcome1 = target.value
                            )
                        )

                        esgPatch(
                            "section2061Outcome1",
                            JsonPrimitive(target.value),
                            EsgSection.ANTI_COMPETITIVE_BEHAVIOR.name,
                            esgReportState.reportFileName
                        )
                    }
                }
                TextField {
                    className = styles.ESG_INPUT_ITEM.cssClass
                    type = InputType.text
                    variant = FormControlVariant.outlined
                    value = antiCompetitiveBehaviorState.section2061Completed2
                    onChange = {
                        val target = it.target as HTMLInputElement
                        setAntiCompetitiveBehaviorState(
                            antiCompetitiveBehaviorState.copy(
                                section2061Completed2 = target.value
                            )
                        )

                        esgPatch(
                            "section2061Completed2",
                            JsonPrimitive(target.value),
                            EsgSection.ANTI_COMPETITIVE_BEHAVIOR.name,
                            esgReportState.reportFileName
                        )
                    }
                }
                TextField {
                    className = styles.ESG_INPUT_ITEM.cssClass
                    type = InputType.text
                    variant = FormControlVariant.outlined
                    value = antiCompetitiveBehaviorState.section2061Outcome2
                    onChange = {
                        val target = it.target as HTMLInputElement
                        setAntiCompetitiveBehaviorState(
                            antiCompetitiveBehaviorState.copy(
                                section2061Outcome2 = target.value
                            )
                        )

                        esgPatch(
                            "section2061Outcome2",
                            JsonPrimitive(target.value),
                            EsgSection.ANTI_COMPETITIVE_BEHAVIOR.name,
                            esgReportState.reportFileName
                        )
                    }
                }
            }
        }
        DialogSpinner {
            open = isLoading
            message = "Downloading..."
        }
    }
}