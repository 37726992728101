package com.ecosave.watch.portal.components.esg.formcontrols

import com.ecosave.watch.portal.helpers.common.YesNoEnum
import com.ecosave.watch.portal.helpers.esg.ReportConstants
import mui.material.BaseTextFieldProps
import mui.material.FormControlLabel
import mui.material.FormControlVariant
import mui.material.Radio
import mui.material.RadioGroup
import mui.material.RadioGroupProps
import mui.material.TextField
import react.FC
import react.ReactNode
import react.create
import web.html.InputType

val ESGTextFieldWrapper = FC<BaseTextFieldProps> { props ->
    TextField {
        +props
        variant = FormControlVariant.outlined
        type = InputType.text
        fullWidth = true
    }
}

val ESGNumberFieldWrapper = FC<BaseTextFieldProps> { props ->
    TextField {
        +props
        variant = FormControlVariant.outlined
        type = InputType.number
        fullWidth = true
    }
}

val ESGTextAreaFieldWrapper = FC<BaseTextFieldProps> { props ->
    TextField {
        +props
        variant = FormControlVariant.outlined
        type = InputType.text
        fullWidth = true
        multiline = true
        minRows = ReportConstants.TEXT_AREA_MIN_ROWS
        maxRows = ReportConstants.TEXT_AREA_MAX_ROWS
    }
}

val ESGRadioButtonWrapper = FC<RadioGroupProps> { props ->
    RadioGroup {
        +props
        FormControlLabel {
            value = YesNoEnum.YES.description
            control = Radio.create()
            label = ReactNode(YesNoEnum.YES.description)
        }
        FormControlLabel {
            value = YesNoEnum.NO.description
            control = Radio.create()
            label = ReactNode(YesNoEnum.NO.description)
        }
    }
}
