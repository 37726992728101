package com.ecosave.watch.portal.helpers.esg

enum class DynamicTableAction(val actionName: String) {
    ADD("add"),
    DELETE("delete")
}

enum class DynamicTableVariant(val variantName: String) {
    LIST("list"),
    TABLE("table")
}

enum class TOCSectionsEnum {
    // General Disclosures
    THE_ORG_AND_ITS_REP_PRACTICES,
    ACTIVITIES_AND_WORKERS,
    GOVERNANCE,
    STRATEGY_POLICIES_PRACTICES,
    STAKEHOLDER_ENGAGEMENT,

    // Other Sections
    INDIRECT_ECONOMIC_IMPACTS,
    PROCUREMENT_PRACTICES,
    ANTI_COMPETITIVE_BEHAVIOR,
    OCCUPATION_HEALTH_AND_SAFETY,
    ECONOMIC_PERFORMANCE,
    TAX,
    MARKET_PRESENCE,
    CUSTOMER_HEALTH_AND_SAFETY

}

enum class EconomicPerformanceField(var fieldName: String) {
    SECTION_201_1_A1("section2011A1"),
    SECTION_201_1_A2("section2011A2"),
    SECTION_201_1_A3("section2011A3"),
    SECTION_201_1_A4("section2011A4"),
    SECTION_201_1_A5("section2011A5"),
    SECTION_201_1_A6("section2011A6"),
    SECTION_201_1_A7("section2011A7"),
    SECTION_201_1_FIELD1("section2011Field1"),
    SECTION_201_1_FIELD2("section2011Field2"),

    SECTION_201_2_RISK("financialRisksList"),
    SECTION_201_2_RISK_A1("section2012RiskA1"),
    SECTION_201_2_RISK_A2("section2012RiskA2"),
    SECTION_201_2_RISK_A3("section2012RiskA3"),
    SECTION_201_2_RISK_A4("section2012RiskA4"),
    SECTION_201_2_RISK_A5("section2012RiskA5"),

    SECTION_201_2_OPPORTUNITY("financialOpportunitiesList"),
    SECTION_201_2_OPPORTUNITY_A1("section2012OpportunityA1"),
    SECTION_201_2_OPPORTUNITY_A2("section2012OpportunityA2"),
    SECTION_201_2_OPPORTUNITY_A3("section2012OpportunityA3"),
    SECTION_201_2_OPPORTUNITY_A4("section2012OpportunityA4"),
    SECTION_201_2_OPPORTUNITY_A5("section2012OpportunityA5"),
    SECTION_201_2_FIELD1("section2012Field1"),

    SECTION_201_3_A1("section2013A1"),
    SECTION_201_3_A2("section2013A2"),
    SECTION_201_3_A3("section2013A3"),
    SECTION_201_3_A4("section2013A4"),
    SECTION_201_3_A5("section2013A5"),


    SECTION_201_3_RETIREMENT_PLAN("retirementPlansList"),
    SECTION_201_3_A6("section2013A6"),
    SECTION_201_3_A7("section2013A7"),
    SECTION_201_3_A8("section2013A8"),
    SECTION_201_3_A9("section2013A9"),
    SECTION_201_3_A10("section2013A10"),
    SECTION_201_3_A11("section2013A11"),

    SECTION_201_4_A1("section2014A1"),
    SECTION_201_4_A2("section2014A2"),
    SECTION_201_4_A3("section2014A3"),
    SECTION_201_4_A4("section2014A4"),
    SECTION_201_4_A5("section2014A5"),
    SECTION_201_4_A6("section2014A6"),
    SECTION_201_4_A7("section2014A7"),
    SECTION_201_4_A8("section2014A8"),

    SECTION_201_4_COUNTRIES_AND_VALUES("countriesAndValuesList"),

    SECTION_201_4_PART_2_A1("section2014Part2A1"),
    SECTION_201_4_PART_2_A2("section2014Part2A2"),
    SECTION_201_4_PART_2_A3("section2014Part2A3"),
    SECTION_201_4_PART_2_A4("section2014Part2A4"),
    SECTION_201_4_PART_2_A5("section2014Part2A5"),
    SECTION_201_4_PART_2_A6("section2014Part2A6"),
    SECTION_201_4_PART_2_A7("section2014Part2A7"),
    SECTION_201_4_PART_2_A8("section2014Part2A8"),
    SECTION_201_4_PART_2_A9("section2014Part2A9"),

    SECTION_201_4_PART2_FIELD1("section2014Part2Field1"),
}

enum class IndirectEconomicImpactsField(var fieldName: String) {
    INFRASTRUCTURE_INVESTMENT_LIST("infrastructureInvestmentList"),
    SECTION_2031PART1A1("section2031Part1A1"),
    SECTION_2031PART1A2("section2031Part1A2"),
    SECTION_2031PART1A3("section2031Part1A3"),

    SERVICE_SUPPORTED_LIST("servicesSupportedList"),
    SECTION_2031PART2A1("section2031Part2A1"),
    SECTION_2031PART2A2("section2031Part2A2"),
    SECTION_2031PART2A3("section2031Part2A3"),

    INDIRECT_ECONOMIC_IMPACT_LIST("indirectEconomicImpactList"),
    SECTION_2032A1("section2032A1"),
    SECTION_2032B1("section2032B1"),
}

enum class TaxField(var fieldName: String) {
    SECTION_207_1_A1("section2071A1"),
    SECTION_207_1_A2("section2071A2"),
    SECTION_207_1_A3("section2071A3"),
    SECTION_207_1_A4("section2071A4"),
    SECTION_207_1_A5("section2071A5"),
    SECTION_207_1_A6("section2071A6"),

    SECTION_207_2_A1("section2072A1"),
    SECTION_207_2_A2("section2072A2"),
    SECTION_207_2_A3("section2072A3"),
    SECTION_207_2_A4("section2072A4"),
    SECTION_207_2_A5("section2072A5"),
    SECTION_207_2_A6("section2072A6"),

    SECTION_207_3_A1("section2073A1"),
    SECTION_207_3_A2("section2073A2"),
    SECTION_207_3_A3("section2073A3")
}

enum class MarketPresenceField(var fieldName: String) {
    SECTION_202_1_FIELD1("section2021Field1"),
    SECTION_202_1_A1("section2021A1"),
    SECTION_202_1_A2("section2021A2"),
    SECTION_202_1_A3("section2021A3"),
    SECTION_202_1_A4("section2021A4"),
    SECTION_202_1_FIELD2("section2021Field2"),
    SECTION_202_1_A5("section2021A5"),
    SECTION_202_1_A6("section2021A6"),
    SECTION_202_1_A7("section2021A7"),
    SECTION_202_1_A8("section2021A8"),
    SECTION_202_1_FIELD3("section2021Field3"),
    SECTION_202_1_FIELD4("section2021Field4"),

    SECTION_202_2_A1("section2022A1"),
    SECTION_202_2_A2("section2022A2"),
    SECTION_202_2_A3("section2022A3"),
    SECTION_202_2_A4("section2022A4")
}

enum class ProcurementPracticesField(var fieldName: String) {
    SECTION_204_1_A1("section2041A1"),
    SECTION_204_1_A2("section2041A2"),
    SECTION_204_1_A3("section2041A3")
}

enum class CustomerHealthNSafetyFeildsEnum(var fieldName: String) {
    SECTION_416_1_A1("section4161A1"),
    SECTION_416_2_CURRENT1("section4162Current1"),
    SECTION_416_2_PREVIOUS1("section4162Previous1"),
    SECTION_416_2_CURRENT2("section4162Current2"),
    SECTION_416_2_PREVIOUS2("section4162Previous2"),
    SECTION_416_2_CURRENT3("section4162Current3"),
    SECTION_416_2_PREVIOUS3("section4162Previous3"),
    SECTION_416_2_CURRENT4("section4162Current4"),
    SECTION_416_2_PREVIOUS4("section4162Previous4"),
    SECTION_416_2_FIELD1("section4162Field1"),
}

enum class TheOrgAndItsRepPracticesField(var fieldName: String) {
    SECTION_2_1_FIELD1("natureOfOwnershipLegalForm"),
    SECTION_2_1_FIELD2("section21Field2"),
    SECTION_2_1_FIELD3("section21Field3"),
    SECTION_2_1_FIELD4("section21Field4"),
    SECTION_2_1_FIELD5("section21Field5"),
    SECTION_2_1_FIELD6("section21Field6"),
    SECTION_2_1_FIELD7("section21Field7"),
    SECTION_2_1_FIELD8("section21Field8"),

    SECTION_2_2_FIELD1("section22Field1"),
    SECTION_2_2_FIELD2("section22Field2"),
    SECTION_2_2_FIELD3("section22Field3"),
    SECTION_2_2_FIELD4("section22Field4"),
    SECTION_2_2_FIELD5("section22Field5"),
    SECTION_2_2_FIELD6("section22Field6"),

    SECTION_2_3_FIELD1("section23Field1"),
    SECTION_2_3_FIELD2("section23Field2"),
    SECTION_2_3_FIELD3("section23Field3"),
    SECTION_2_3_FIELD4("section23Field4"),
    SECTION_2_3_FIELD5("section23Field5"),

    SECTION_2_5_FIELD1("section25Field1"),
    SECTION_2_5_FIELD2("section25Field2"),
    SECTION_2_5_FIELD3("section25Field3"),
    SECTION_2_5_FIELD4("section25Field4"),
    SECTION_2_5_FIELD5("section25Field5"),
    SECTION_2_5_FIELD6("section25Field6")
}

enum class GovernanceField(var fieldName: String) {
    SECTION_2_9_A1("section29A1"),
    SECTION_2_9_A2("section29A2"),
    SECTION_2_9_A3("section29A3"),
    SECTION_2_9_A4("section29A4"),
    SECTION_2_9_A5("section29A5"),
    SECTION_2_9_A6("section29A6"),
    SECTION_2_9_A7("section29A7"),
    SECTION_2_9_A8("section29A8"),
    SECTION_2_9_A9("section29A9"),
    SECTION_2_9_A10("section29A10"),
    SECTION_2_9_FIELD1("section29Field1"),
    SECTION_2_9_FIELD2("section29Field2"),

    SECTION_2_10_A1("section210A1"),
    SECTION_2_10_A2("section210A2"),
    SECTION_2_10_A3("section210A3"),
    SECTION_2_10_A4("section210A4"),
    SECTION_2_10_A5("section210A5"),
    SECTION_2_10_A6("section210A6"),
    SECTION_2_10_FIELD1("section210Field1"),

    SECTION_2_11_A1("section211A1"),
    SECTION_2_11_A2("section211A2"),

    SECTION_2_12_A1("section212A1"),
    SECTION_2_12_A2("section212A2"),
    SECTION_2_12_A3("section212A3"),
    SECTION_2_12_A4("section212A4"),
    SECTION_2_12_A5("section212A5"),

    SECTION_2_13_A1("section213A1"),
    SECTION_2_13_A2("section213A2"),
    SECTION_2_13_A3("section213A3"),
    SECTION_2_13_A4("section213A4"),

    SECTION_2_14_A1("section214A1"),
    SECTION_2_14_A2("section214A2"),

    SECTION_2_15_A1("section215A1"),
    SECTION_2_15_A2("section215A2"),
    SECTION_2_15_A3("section215A3"),
    SECTION_2_15_A4("section215A4"),
    SECTION_2_15_A5("section215A5"),
    SECTION_2_15_A6("section215A6"),

    SECTION_2_16_A1("section216A1"),
    SECTION_2_16_A2("section216A2"),

    SECTION_2_17_A1("section217A1"),

    SECTION_2_18_A1("section218A1"),
    SECTION_2_18_A2("section218A2"),
    SECTION_2_18_A3("section218A3"),
    SECTION_2_18_A4("section218A4"),

    SECTION_2_19_A1("section219A1"),
    SECTION_2_19_A2("section219A2"),
    SECTION_2_19_A3("section219A3"),
    SECTION_2_19_A4("section219A4"),
    SECTION_2_19_A5("section219A5"),
    SECTION_2_19_A6("section219A6"),
    SECTION_2_19_A7("section219A7"),

    SECTION_2_20_A1("section220A1"),
    SECTION_2_20_A2("section220A2"),
    SECTION_2_20_A3("section220A3"),
    SECTION_2_20_A4("section220A4"),
    SECTION_2_20_A5("section220A5"),

    SECTION_2_21_B1("section22B1"),
    SECTION_2_21_B2("section22B2"),
    SECTION_2_21_B3("section22B3"),
    SECTION_2_21_B4("section22B4"),
    SECTION_2_21_B5("section22B5"),
    SECTION_2_21_B6("section22B6"),

    SECTION_2_21_C1("section22C1"),
    SECTION_2_21_C2("section22C2"),
    SECTION_2_21_C3("section22C3"),
    SECTION_2_21_C4("section22C4"),
    SECTION_2_21_C5("section22C5"),
    SECTION_2_21_C6("section22C6"),
}

enum class ActivitiesAndWorkersField(var fieldName: String) {
    SECTION_2_6_FIELD1("section26Field1"),
    SECTION_2_6_FIELD2("section26Field2"),
    SECTION_2_6_FIELD3("section26Field3"),
    SECTION_2_6_FIELD4("section26Field4"),

    SECTION_2_7_A1("section27A1"),
    SECTION_2_7_A2("section27A2"),
    SECTION_2_7_A3("section27A3"),
    SECTION_2_7_A4("section27A4"),
    SECTION_2_7_A5("section27A5"),
    SECTION_2_7_A6("section27A6"),
    SECTION_2_7_A7("section27A7"),
    SECTION_2_7_A8("section27A8"),
    SECTION_2_7_A9("section27A9"),
    SECTION_2_7_A10("section27A10"),
    SECTION_2_7_A11("section27A11"),
    SECTION_2_7_A12("section27A12"),
    SECTION_2_7_A13("section27A13"),
    SECTION_2_7_A14("section27A14"),
    SECTION_2_7_A15("section27A15"),
    SECTION_2_7_A16("section27A16"),
    SECTION_2_7_A17("section27A17"),
    SECTION_2_7_A18("section27A18"),
    SECTION_2_7_A19("section27A19"),
    SECTION_2_7_A20("section27A20"),
    SECTION_2_7_A21("section27A21"),
    SECTION_2_7_A22("section27A22"),
    SECTION_2_7_A23("section27A23"),
    SECTION_2_7_A24("section27A24"),
    SECTION_2_7_B1("section27B1"),
    SECTION_2_7_B2("section27B2"),
    SECTION_2_7_B3("section27B3"),
    SECTION_2_7_B4("section27B4"),
    SECTION_2_7_B5("section27B5"),
    SECTION_2_7_B6("section27B6"),
    SECTION_2_7_B7("section27B7"),
    SECTION_2_7_B8("section27B8"),
    SECTION_2_7_B9("section27B9"),
    SECTION_2_7_B10("section27B10"),
    SECTION_2_7_B11("section27B11"),
    SECTION_2_7_B12("section27B12"),
    SECTION_2_7_B13("section27B13"),
    SECTION_2_7_B14("section27B14"),
    SECTION_2_7_B15("section27B15"),
    SECTION_2_7_B16("section27B16"),
    SECTION_2_7_B17("section27B17"),
    SECTION_2_7_B18("section27B18"),
    SECTION_2_7_B19("section27B19"),
    SECTION_2_7_B20("section27B20"),
    SECTION_2_7_B21("section27B21"),
    SECTION_2_7_B22("section27B22"),
    SECTION_2_7_B23("section27B23"),
    SECTION_2_7_B24("section27B24"),
    SECTION_2_7_FIELD1("section27Field1")
}

enum class ReportSummaryEnums(val description: String) {
    REPORT_FILE_NAME("Report File Name"),
    REPORT_TITLE("Report Title"),
    REPORT_FINANCIAL_YEAR("Report Financial Year"),
    LAST_MODIFIED_BY("Last Modified By"),
    LAST_MODIFIED_TIME("Last Modified Time"),
    PERCENTAGE_COMPLETE("% Complete"),
    REPORT_ACTIONS("Actions")
}

enum class EsgSection(val description: String, val info: String) {
    GENERAL_DISCLOSURES(
        "Section 2: General Disclosures",
        "General Disclosures contains disclosures for organizations to provide information about their reporting practices; activities and workers; governance; strategy, policies, and practices; and stakeholder engagement. This information gives insight into the profile and scale of organizations and provides a context for understanding their impacts. "
    ),
    MATERIAL_TOPICS(
        "Section 3: Material Topics",
        "Material Topics provides step-by-step guidance for organizations on how to determine material topics. It also explains how the Sector Standards are used in this process. Material topics are topics that represent an organization’s most significant impacts on the economy, environment, and people, including impacts on their human rights."
    ),
    ECONOMIC_PERFORMANCE(
        "Section 201: Economic performance",
        "This Standard addresses the topic of economic performance. This includes the economic value generated and distributed (EVG&D) by an organization, its defined benefit plan obligations, the financial assistance it receives from any government, and the financial implications of climate change. "
    ),
    MARKET_PRESENCE(
        "Section 202: Market presence",
        "This Standard addresses the topic of an organization’s market presence, covering its contribution to economic development in the local areas or communities where it operates. For example, this can include the organization’s approaches to remuneration or local hiring. "
    ),
    INDIRECT_ECONOMIC_IMPACTS(
        "Section 203: Indirect economic impacts",
        "This Standard addresses an organization’s indirect economic impacts, including impacts of an organization’s infrastructure investments and services supported. "
    ),
    PROCUREMENT_PRACTICES(
        "Section 204: Procurement practices",
        "This Standard addresses the topic of procurement practices. This covers an organization’s support for local suppliers or those owned by women or members of vulnerable groups. It also covers how the organization’s procurement practices (such as the lead times it gives to suppliers, or the purchasing prices it negotiates) cause or contribute to negative impacts in the supply chain. "
    ),
    ANTI_CORRUPTION(
        "Section 205: Anti-corruption",
        "This Standard addresses the topic of anti-corruption. In this Standard, corruption is understood to include practices such as bribery, facilitation payments, fraud, extortion, collusion, and money laundering; the offer or receipt of gifts, loans, fees, rewards, or other advantages as an inducement to do something that is dishonest, illegal, or represents a breach of trust. It can also include practices such as embezzlement, trading in influence, abuse of function, illicit enrichment, concealment, and obstructing justice. "
    ),
    ANTI_COMPETITIVE_BEHAVIOR(
        "Section 206: Anti-competitive behavior",
        "This Standard addresses the topic of anti-competitive behavior, including anti-trust and monopoly practices. "
    ),
    TAX("Section 207: Tax", "This Standard addresses the topic of tax. "),
    MATERIALS(
        "Section 301: Materials",
        "Materials contains disclosures for organizations to report information about their materials- related impacts, and how they manage these impacts."
    ),
    ENERGY(
        "Section 302: Energy",
        "Energy contains disclosures for organizations to report information about their energy-related impacts, and how they manage these impacts."
    ),
    WATER_AND_EFFLUENTS(
        "Section 303: Water and effluents",
        "Water and Effluents contains disclosures for organizations to report information about their water- related impacts, and how they manage these impacts."
    ),
    BIODIVERSITY(
        "Section 304: Biodiversity",
        "Biodiversity contains disclosures for organizations to report information about their biodiversity-related impacts, and how they manage these impacts."
    ),
    EMISSIONS(
        "Section 305: Emissions",
        "Emissions contains disclosures for organizations to report information about their emissions-related impacts, and how they manage these impacts."
    ),
    WASTE(
        "Section 306: Waste",
        "Waste contains disclosures for organizations to report information about their waste-related impacts, and how they manage these impacts. The disclosures enable an organization to provide information on how it prevents waste generation and how it manages waste that cannot be prevented, in its own activities and upstream and downstream in its value chain."
    ),
    ENVIRONMENTAL_COMPLIANCE(
        "Section 307: Environmental compliance",
        "Environmental Compliance sets out reporting requirements on the topic of environmental compliance. This Standard can be used by an organization of any size, type, sector or geographic location that wants to report on its impacts related to this topic."
    ),
    SUPPLIER_ENVIRONMENTAL_ASSESSMENT(
        "Section 308: Supplier environmental assessment",
        "Supplier Environmental Assessment contains disclosures for organizations to report information about their environmental impacts in their supply chain, and how they manage these impacts. The disclosures enable an organization to provide information on its approach to preventing and mitigating negative environmental impacts in its supply chain."
    ),
    EMPLOYMENT(
        "Section 401: Employment",
        "Employment contains disclosures for organizations to report information about their employment- related impacts, and how they manage these impacts."
    ),
    MANAGEMENT_RELATIONS(
        "Section 402: Labor/management relations",
        "Labor/Management Relations contains disclosures for organizations to report information about their impacts related to labor/management relations, and how they manage these impacts."
    ),
    OCCUPATION_HEALTH_AND_SAFETY(
        "Section 403: Occupation health and safety",
        "Occupational Health and Safety contains disclosures for organizations to report information about their occupational health and safety-related impacts, and how they manage these impacts."
    ),
    TRAINING_AND_EDUCATION(
        "Section 404: Training and education",
        "Training and Education contains disclosures for organizations to report information about their training and education-related impacts, and how they manage these impacts."
    ),
    DIVERSITY_AND_EQUAL_OPPORTUNITY(
        "Section 405: Diversity and equal opportunity",
        "Diversity and Equal Opportunity contains disclosures for organizations to report information about their impacts related to diversity and equal opportunity at work, and how they manage these impacts."
    ),
    NON_DISCRIMINATION(
        "Section 406: Non-discrimination",
        "Non-discrimination contains disclosures for organizations to report information about their impacts related to discrimination, and how they manage these impacts."
    ),
    FREEDOM_OF_ASSOCIATION_AND_COLLECTIVE_BARGAINING(
        "Section 407: Freedom of association and collective bargaining",
        "Freedom of Association and Collective Bargaining contains disclosures for organizations to report information about their impacts related to freedom of association and collective bargaining, and how they manage these impacts."
    ),
    CHILD_LABOR(
        "Section 408: Child labor",
        "Child Labor contains disclosures for organizations to report information about their impacts related to child labor, and how they manage these impacts."
    ),
    FORCED_OR_COMPULSORY_LABOR(
        "Section 409: Forced or compulsory labor",
        "Forced or Compulsory Labor contains disclosures for organizations to report information about their impacts related to forced or compulsory labor, and how they manage these impacts."
    ),
    SECURITY_PRACTICES(
        "Section 410: Security practices",
        "Security Practices contains disclosures for organizations to report information about their security practice-related impacts, and how they manage these impacts."
    ),
    RIGHTS_OF_INDIGENOUS_PEOPLES(
        "Section 411: Rights of indigenous peoples",
        "Rights of Indigenous Peoples contains disclosures for organizations to report information about their impacts related to the rights of indigenous peoples, and how they manage these impacts."
    ),
    HUMANS_RIGHTS_ASSESSMENT(
        "Section 412: Humans rights assessment",
        "Section 412 addresses the topic of human rights assessment. The international standard that establishes the expectations of responsible conduct for organizations with respect to human rights is the United Nations (UN) 'Guiding Principles on Business and Human Rights', endorsed by the UN Human Rights Council in 2011."
    ),
    LOCAL_COMMUNITY(
        "Section 413: Local community",
        "Local Communities contains disclosures for organizations to report information about their impacts related to local communities, and how they manage these impacts."
    ),
    SUPPLIER_SOCIAL_ASSESSMENT(
        "Section 414: Supplier social assessment",
        "Supplier Social Assessment contains disclosures for organizations to report information about their social impacts in their supply chain, and how they manage these impacts. The disclosures enable an organization to provide information on its approach to preventing and mitigating negative social impacts in its supply chain."
    ),
    PUBLIC_POLICY(
        "Section 415: Public policy",
        "Public Policy contains disclosures for organizations to report information about their public policy- related impacts, and how they manage these impacts."
    ),
    CUSTOMER_HEALTH_AND_SAFETY(
        "Section 416: Customer health and safety",
        "Customer Health and Safety contains disclosures for organizations to report information about their impacts related to customer health and safety, and how they manage these impacts."
    ),
    MARKETING_AND_LABELING(
        "Section 417: Marketing and labeling",
        "Section 417 addresses the topic of product and service information and labeling and marketing communications."
    ),
    CUSTOMER_PRIVACY(
        "Section 418: Customer privacy",
        "Marketing and Labeling contains disclosures for organizations to report information about their impacts related to marketing and labeling, and how they manage these impacts."
    ),
    SOCIOECONOMIC_COMPLIANCE(
        "Section 419: Socioeconomic compliance",
        "Section 419 addresses the topic of socioeconomic compliance. This includes an organization's overall compliance record, as well as compliance with specific laws or regulations in the social and economic area."
    ),
}

enum class EsgSubSection(val mainSection: EsgSection, val subSection: String) {
    // General Disclosures Subsections
    THE_ORG_AND_ITS_REP_PRACTICES(EsgSection.GENERAL_DISCLOSURES, "The Organization and it's reporting practices."),
    ACTIVITIES_AND_WORKERS(EsgSection.GENERAL_DISCLOSURES, "Activities and Workers"),
    GOVERNANCE(EsgSection.GENERAL_DISCLOSURES, "Governance"),
    STRATEGY_POLICIES_PRACTICES(EsgSection.GENERAL_DISCLOSURES, "Strategy, policies, and practices"),
    STAKEHOLDER_ENGAGEMENT(EsgSection.GENERAL_DISCLOSURES, "Stakeholder engagement")
}

enum class CreateReportFields(val label: String) {
    REPORT_NAME("Report Name (Filename) *"),
    REPORT_TITLE("Report Title *"),
    COMPANY_NAME("Company Name  *"),
    REPORT_FINANCIAL_INFO("Reporting Financial Information Details *"),
    REPORT_FINANCIAL_YEAR("Financial Year (yyyy)  *"),
    REPORT_CAL_DATE_START("Calendar Date Start *"),
    REPORT_CAL_DATE_END("Calendar Date End *")
}

enum class ESGHeaders(val header: String) {
    REPORT_NAME("Report Name"),
    STATUS("Status"),
    FINANCIAL_YEAR("Financial Year"),
    CREATED_OR_MODIFIED_BY("Created/Modified By"),
    CREATED_OR_MODIFIED_ON("Created/Modified On"),
    PERCENTAGE_COMPLETED("% Completed"),
}