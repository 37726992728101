package com.ecosave.watch.portal.models.esg
import kotlinx.serialization.Serializable
@Serializable
data class MarketingAndLabelingState(
    var section4171A1: Int? = null,
    var section4171A2: Int? = null,
    var section4171A3: Int? = null,
    var section4171A4: Int? = null,
    var section4171A5: Int? = null,
    var section4171A6: Double? = null,
    var section4172Current1: Int? = null,
    var section4172Current2: Int? = null,
    var section4172Current3: Int? = null,
    var section4172Current4: Int? = null,
    var section4172Previous1: Int? = null,
    var section4172Previous2: Int? = null,
    var section4172Previous3: Int? = null,
    var section4172Previous4: Int? = null,
    var section4173Current1: Int? = null,
    var section4173Current2: Int? = null,
    var section4173Current3: Int? = null,
    var section4173Current4: Int? = null,
    var section4173Previous1: Int? = null,
    var section4173Previous2: Int? = null,
    var section4173Previous3: Int? = null,
    var section4173Previous4: Int? = null,
)
