package com.ecosave.watch.portal.components.common

import com.ecosave.watch.portal.styles.CommonStyles
import js.core.jso
import mui.material.Box
import mui.material.Dialog
import mui.material.DialogProps
import mui.material.DialogTitle
import mui.material.Typography
import mui.material.TypographyAlign
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.FC
import web.cssom.ClassName
import web.cssom.FontWeight
import web.cssom.Margin
import web.cssom.pct
import web.cssom.px

external interface DialogWrapperProps : DialogProps {
    var dialogTitle: String
    var dialogMaxWidth: Number
    var dialogMaxWidthIsPercent: Boolean
}

val DialogWrapper = FC<DialogWrapperProps> { props ->
    Dialog {
        +props
        fullWidth = true
        PaperProps = jso {
            className = CommonStyles.DIALOG_BORDER_RADIUS.cssClass
            sx {
                maxWidth = if (props.dialogMaxWidthIsPercent) props.dialogMaxWidth.pct else props.dialogMaxWidth.px
            }
        }
        Box {
            className = ClassName("dialog-gradient-strip")
        }
        DialogTitle {
            Typography {
                sx {
                    margin = Margin(10.px, 0.px, (-10).px, 0.px)
                    fontWeight = FontWeight.bold
                }
                variant = TypographyVariant.h5
                align = TypographyAlign.center
                +props.dialogTitle
            }
        }
        +props.children
    }
}