package com.ecosave.watch.portal.models.esg

import kotlinx.serialization.Serializable

@Serializable
data class AntiCompetitiveBehaviorState(
    var section2061Number: Int? = null,
    var section2061Completed1: String? = null,
    var section2061Completed2: String? = null,
    var section2061Outcome1: String? = null,
    var section2061Outcome2: String? = null,
)
