package com.ecosave.watch.portal.pages

import com.ecosave.watch.portal.components.esg.EsgMainComponent
import com.ecosave.watch.portal.components.esg.EsgReportSummaryComponent
import com.ecosave.watch.portal.models.esg.*
import react.FC
import react.Props
import react.useState


val EsgSummaryPage = FC<Props> {
    val (summaryOrMainComponent, setSummaryOrMainComponent) = useState(false)
    val (pageNumber, setPageNumber) = useState(0)
    val (esgReportValidation, setEsgReportValidation) = useState(EsgReportValidationState(reportFinancialYear = ReportFinancialYearValidationState()))
    val (esgReportState, setEsgReportState) = useState(
        EsgReportState(
            reportFinancialYear = ReportFinancialYear(),
            report = EsgReportData(
                theOrgAndItsRepPractices = TheOrgAndItsRepPracticesState(),
                activitiesAndWorkers = ActivitiesAndWorkersState(),
                governance = GovernanceState(),
                strategyPoliciesPractices = StrategyPoliciesPracticesState(),
                stakeholderEngagement = StakeholderEngagementState(),
                economicPerformance = EconomicPerformanceState(),
                indirectEconomicImpacts = IndirectEconomicImpactsState(),
                procurementPractices = ProcurementPracticesState(),
                antiCompetitiveBehavior = AntiCompetitiveBehaviorState(),
                reportCoverInfo = ReportCoverInfoState(),
                tax = TaxState(),
                occupationalHealthAndSafety = OccupationalHealthAndSafetyState(),
                marketPresence = MarketPresenceState(),
                customerHealthAndSafety = CustomerHealthAndSafetyState()
            )
        )
    )

    if (summaryOrMainComponent) {
        EsgMainComponent {
            esgReportStateProps = esgReportState
            esgReportStateSetterProps = setEsgReportState
            esgReportValidationStateProps = esgReportValidation
            esgReportValidationStateSetterProps = setEsgReportValidation
            esgReportSummarySwitchStateSetterProps = setSummaryOrMainComponent
            esgReportPageNumber = pageNumber
            esgReportPageNumberSetter = setPageNumber
        }
    } else {
        EsgReportSummaryComponent {
            esgReportStateProps = esgReportState
            esgReportStateSetterProps = setEsgReportState
            esgReportIsClickedStateProps = summaryOrMainComponent
            esgReportIsClickedStateSetterProps = setSummaryOrMainComponent
            esgReportPageNumber = pageNumber
            esgReportPageNumberSetter = setPageNumber
        }
    }
}