package com.ecosave.watch.portal.services.esg

import com.ecosave.watch.portal.helpers.BackendReportingServiceURL
import com.ecosave.watch.portal.helpers.common.ApiCallStatus
import com.ecosave.watch.portal.helpers.esg.EsgSection
import com.ecosave.watch.portal.models.esg.EsgCollectionAddRow
import com.ecosave.watch.portal.models.esg.EsgCollectionDeleteRow
import com.ecosave.watch.portal.models.esg.EsgCollectionPatchUpdate
import com.ecosave.watch.portal.models.esg.EsgPatchUpdate
import com.ecosave.watch.portal.models.esg.EsgReportState
import com.ecosave.watch.portal.models.esg.UpdateEnRichedToc
import com.ecosave.watch.portal.models.esg.UpdateEsgSection
import com.ecosave.watch.portal.services.httpClient
import io.ktor.client.call.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import io.ktor.http.*
import kotlinx.browser.document
import org.w3c.dom.HTMLAnchorElement
import org.w3c.dom.url.URL
import org.w3c.files.Blob
import org.w3c.files.BlobPropertyBag


suspend fun createEsgReport(
    esgReportState: EsgReportState
): HttpStatusCode? {
    try {
        val response: HttpResponse = httpClient.post("$BackendReportingServiceURL/esg-report") {
            setBody(esgReportState)
        }
        return when (response.status) {
            HttpStatusCode.Created -> HttpStatusCode.Created
            HttpStatusCode.Conflict -> HttpStatusCode.Conflict
            else -> null
        }
    } catch (e: dynamic) {
        console.log("Error: ", e.message)
    }
    return null
}

suspend fun getSingleReport(reportFileName: String): EsgReportState? {
    try {
        val response: HttpResponse =
            httpClient.get("$BackendReportingServiceURL/esg-report/?reportFileName=${reportFileName}")
        if (response.status == HttpStatusCode.OK) {
            return response.body()
        }
    } catch (e: dynamic) {
        console.log("Error: ", e.message)
    }
    return null
}

suspend fun esgDownloadReport(reportFileName: String): ApiCallStatus {
    return try {
        val pdfReport = downloadReport(reportFileName)
        if (pdfReport != null) {
            downloadPdf(pdfReport, reportFileName)
            ApiCallStatus.SUCCESS
        } else {
            ApiCallStatus.FAILURE

        }
    } catch (e: Exception) {
        console.error(e.message)
        ApiCallStatus.FAILURE
    }
}

suspend fun downloadReport(reportFileName: String): ByteArray? {
    try {
        val response: HttpResponse =
            httpClient.get("$BackendReportingServiceURL/esg-report/download-report?reportFileName=$reportFileName")
        if (response.status == HttpStatusCode.OK) {
            return response.readBytes()
        }
    } catch (e: Exception) {
        console.log("Error: ${e.message}")
    }
    return null
}

suspend fun esgDownloadReportSection(reportFileName: String, section: EsgSection? = null): ApiCallStatus {
    return try {
        val pdfReport = downloadReportSection(reportFileName, section)

        if (pdfReport != null) {
            downloadPdf(pdfReport, reportFileName)
            ApiCallStatus.SUCCESS
        } else {
            ApiCallStatus.FAILURE
        }
    } catch (e: Exception) {
        console.error(e.message)
        ApiCallStatus.FAILURE
    }
}

suspend fun downloadReportSection(reportFileName: String, section: EsgSection? = null): ByteArray? {
    try {
        val response: HttpResponse =
            httpClient.get("$BackendReportingServiceURL/esg-report/preview-report?reportFileName=$reportFileName&section=$section")
        if (response.status == HttpStatusCode.OK) {
            return response.readBytes()
        }
    } catch (e: Exception) {
        console.log("Error: ${e.message}")
    }
    return null
}

suspend fun getAllReports(): List<EsgReportState>? {
    try {
        val response: HttpResponse = httpClient.get("$BackendReportingServiceURL/esg-report/all")

        if (response.status == HttpStatusCode.OK) {
            return response.body()
        }
    } catch (e: dynamic) {
        console.log("Error: ", e.message)
    }
    return null
}

suspend fun deleteReport(reportFileName: String): ApiCallStatus {
    try {
        val response: HttpResponse =
            httpClient.delete("$BackendReportingServiceURL/esg-report/?reportFileName=$reportFileName")
        if (response.status == HttpStatusCode.OK) {
            return ApiCallStatus.SUCCESS
        }
    } catch (e: dynamic) {
        console.log("Error: ", e.message)
    }
    return ApiCallStatus.FAILURE
}

suspend fun updateEsgReport(esgState: EsgReportState): EsgReportState? {

    try {
        val response: HttpResponse = httpClient.put("$BackendReportingServiceURL/esg-report/update-esg-sections") {
            setBody(UpdateEsgSection(esgState.reportFileName, esgState.sections))
        }
        if (response.status == HttpStatusCode.OK) {
            val reportState = response.body<EsgReportState>()
            reportState.sections = esgState.sections
            return reportState
        }
    } catch (e: dynamic) {
        console.log("Error: ", e.message)
    }
    return null
}

suspend fun patchEsgReport(patch: EsgPatchUpdate): EsgReportState? {
    try {
        val response: HttpResponse = httpClient.patch("$BackendReportingServiceURL/esg-report") {
            setBody(patch)
        }
        if (response.status == HttpStatusCode.OK) {
            val report: EsgReportState = response.body()
            return report
        }
    } catch (e: dynamic) {
        console.log("Error: ", e.message)
    }
    return null
}

suspend fun addDynamicRowOrSection(patch: EsgCollectionAddRow): ApiCallStatus {
    try {
        val response: HttpResponse = httpClient.post("$BackendReportingServiceURL/esg-report/collection-item") {
            setBody(patch)
        }
        if (response.status == HttpStatusCode.Created) {
            return ApiCallStatus.SUCCESS
        }
    } catch (e: dynamic) {
        console.log("Error: ", e.message)
    }
    return ApiCallStatus.FAILURE
}

suspend fun deleteDynamicRowOrSection(patch: EsgCollectionDeleteRow): ApiCallStatus {
    try {
        val response: HttpResponse =
            httpClient.delete("$BackendReportingServiceURL/esg-report/collection-item?objectPatchEnum=${patch.objectPatchEnum}&reportFileName=${patch.reportFileName}&pathFirst=${patch.pathFirst}&indexAt=${patch.indexAt}")
        if (response.status == HttpStatusCode.OK) {
            return ApiCallStatus.SUCCESS
        }
    } catch (e: dynamic) {
        console.log("Error: ", e.message)
    }
    return ApiCallStatus.FAILURE
}

suspend fun autoSaveEsg(patch: EsgPatchUpdate): ApiCallStatus {
    try {
        val response: HttpResponse = httpClient.patch("$BackendReportingServiceURL/esg-report") {
            setBody(patch)
        }
        if (response.status == HttpStatusCode.OK) {
            return ApiCallStatus.SUCCESS
        }
    } catch (e: dynamic) {
        console.log("Error: ", e.message)
    }
    return ApiCallStatus.FAILURE
}

suspend fun autoSaveEsgCollection(patch: EsgCollectionPatchUpdate): ApiCallStatus {
    try {
        val response: HttpResponse = httpClient.patch("$BackendReportingServiceURL/esg-report/collection-item") {
            setBody(patch)
        }
        if (response.status == HttpStatusCode.OK) {
            return ApiCallStatus.SUCCESS
        }
    } catch (e: dynamic) {
        console.log("Error: ", e.message)
    }
    return ApiCallStatus.FAILURE
}

suspend fun updateEnrichedToc(tableOfContent: UpdateEnRichedToc, percentageCompleted: Int) {
    tableOfContent.mainTableOfContents.totalPercentCompleted = percentageCompleted
    try {
        httpClient.put("$BackendReportingServiceURL/esg-report/update-enriched-toc") {
            setBody(tableOfContent)
        }
    } catch (e: dynamic) {
        console.log("Error: ${e.message}")
    }
}


fun downloadPdf(pdfReport: dynamic, reportFileName: String) {
    val blob = Blob(arrayOf(pdfReport), BlobPropertyBag("application/pdf"))
    val url = URL.createObjectURL(blob)
    val a = document.createElement("a") as HTMLAnchorElement
    a.href = url
    a.download = reportFileName
    a.click()
}