package com.ecosave.watch.portal.pages

import com.ecosave.watch.portal.components.common.TableHeader
import com.ecosave.watch.portal.components.utilityaccounts.UtilityAccountSummaryFilterComponent
import com.ecosave.watch.portal.components.utilityaccounts.UtilityAccountSummaryTableRow
import com.ecosave.watch.portal.helpers.billing.BillingConstants.utilityAccountSummaryHeadersList
import com.ecosave.watch.portal.helpers.billing.getUtilityAccountsFilterParams
import com.ecosave.watch.portal.helpers.common.AutoCompleteOption
import com.ecosave.watch.portal.helpers.common.PageRoutes
import com.ecosave.watch.portal.helpers.common.PageTitles
import com.ecosave.watch.portal.helpers.common.isOnboardingCompleted
import com.ecosave.watch.portal.helpers.usermanagement.OnBoardingStatus
import com.ecosave.watch.portal.models.billing.FacilityAccountSummary
import com.ecosave.watch.portal.models.billing.UtilityAccountFiltersDataClass
import com.ecosave.watch.portal.services.billing.getBillExist
import com.ecosave.watch.portal.services.billing.getFacilitiesForAccountSummary
import com.ecosave.watch.portal.services.billing.getFiltersValue
import com.ecosave.watch.portal.services.getOnboardingStatus
import com.ecosave.watch.portal.useGlobalState
import kotlinx.coroutines.launch
import mui.icons.material.KeyboardArrowDown
import mui.icons.material.KeyboardArrowRight
import mui.icons.material.KeyboardArrowUp
import mui.material.Alert
import mui.material.AlertColor
import mui.material.AlertTitle
import mui.material.AlertVariant
import mui.material.Box
import mui.material.Button
import mui.material.ButtonColor
import mui.material.ButtonVariant
import mui.material.IconButton
import mui.material.Table
import mui.material.TableBody
import mui.material.TableCell
import mui.material.TableCellAlign
import mui.material.TableContainer
import mui.material.TableHead
import mui.material.TableRow
import mui.material.Tooltip
import mui.system.sx
import react.FC
import react.Props
import react.ReactNode
import react.router.useNavigate
import react.useEffect
import react.useEffectOnce
import react.useState
import web.cssom.ClassName
import web.cssom.Display
import web.cssom.FlexDirection
import web.cssom.JustifyContent
import web.cssom.px


val UtilityAccounts = FC<Props> {

    val globalState = useGlobalState()
    val (facilityList, facilityListStateSetter) = useState(listOf<FacilityAccountSummary>())
    val navigate = useNavigate()
    var isLoading by useState(false)
    var expandAllRows by useState(false)
    val (filters, filtersStateSetter) = useState(UtilityAccountFiltersDataClass())
    var facilityFilterOptions by useState<Array<AutoCompleteOption>>(emptyArray())
    var streetFilterOptions by useState<Array<AutoCompleteOption>>(emptyArray())
    var zipcodeFilterOptions by useState<Array<AutoCompleteOption>>(emptyArray())
    var cityFilterOptions by useState<Array<AutoCompleteOption>>(emptyArray())
    var stateFilterOptions by useState<Array<AutoCompleteOption>>(emptyArray())
    var activeFiltersCount by useState(0)
    var helperMessage by useState("")
    val (localOnboardingStatus, setLocalOnboardingStatus) = useState(globalState.userData.onBoardingStatus)
    val (fetchOnboardingStatus, setFetchOnboardingStatus) = useState(false)
    var isFetching by useState(false)

    useEffectOnce {
        if (isOnboardingCompleted(globalState.userData.onBoardingStatus)) {

            globalState.updatePageTitle(PageTitles.UTILITY_ACCOUNTS.title)
            mainScope.launch {
                isLoading = true
                val response = getFiltersValue()

                facilityFilterOptions = response.facilities.map {
                    AutoCompleteOption(it.facilityName, it.facilityId.toString())
                }.toTypedArray()
                streetFilterOptions = response.streets.map {
                    AutoCompleteOption(it, it)
                }.toTypedArray()
                zipcodeFilterOptions = response.zipcodes.map {
                    AutoCompleteOption(it, it)
                }.toTypedArray()
                cityFilterOptions = response.cities.map {
                    AutoCompleteOption(it, it)
                }.toTypedArray()
                stateFilterOptions = response.states.map {
                    AutoCompleteOption(it, it)
                }.toTypedArray()
                isLoading = false
            }
        } else {
            // local onboarding status set during onboarding refresh to show info alerts
            mainScope.launch {
                isLoading = true
                val status = getOnboardingStatus()
                if (status != null) {
                    setLocalOnboardingStatus(status)
                }
                isLoading = false
            }
        }
    }

    useEffect(filters) {
        mainScope.launch {
            isLoading = true
            var params = "?"
            val filterParams = getUtilityAccountsFilterParams(filters)
            params += filterParams.params
            activeFiltersCount = filterParams.counter
            val list = getFacilitiesForAccountSummary(params)
            if (list == null) {
                helperMessage =
                    "There has been an issue fetching facilities and associated utility accounts, please retry."
                facilityListStateSetter(emptyList())
            } else if (list.isEmpty()) {
                helperMessage = "No facilities and associated utility accounts found."
                facilityListStateSetter(emptyList())
            } else {
                facilityListStateSetter(list)
            }

            isLoading = false
        }
    }

    // Onboarding Logic Start
    useEffect(fetchOnboardingStatus) {
        // executed during onboarding account add, account edit, bill add to show info messages.
        if (fetchOnboardingStatus) {
            mainScope.launch {
                val status = getOnboardingStatus()
                if (status != null) {
                    setLocalOnboardingStatus(status)
                }
            }
            setFetchOnboardingStatus(false)
        }
    }
    // Onboarding Logic End

    Box {
        className = ClassName("utility-account-summary-container")

        // Onboarding Logic Start
        if (!isOnboardingCompleted(globalState.userData.onBoardingStatus)) {

            if (localOnboardingStatus == OnBoardingStatus.UTILITY_ACCOUNT_PENDING || localOnboardingStatus == OnBoardingStatus.MANUAL_BILL_PENDING) {
                Alert {
                    color = AlertColor.warning
                    severity = AlertColor.info
                    variant = AlertVariant.outlined
                    if (localOnboardingStatus == OnBoardingStatus.UTILITY_ACCOUNT_PENDING)
                        +"Please add at least one utility account for at least one facility."
                    else if (localOnboardingStatus == OnBoardingStatus.MANUAL_BILL_PENDING)
                        +"Please add at least one energy bill to complete onboarding."
                }
            }

            if (localOnboardingStatus == OnBoardingStatus.DONE) {
                Alert {
                    color = AlertColor.success
                    severity = AlertColor.success
                    variant = AlertVariant.outlined
                    AlertTitle {
                        +"Onboarding Completed"
                    }
                    Box {
                        sx {
                            display = Display.flex
                            flexDirection = FlexDirection.column
                            gap = 10.px
                        }
                        Box {
                            +"Congratulations, your onboarding has been completed successfully, you can add more utility accounts and bills later."
                        }
                        Box {
                            Button {
                                variant = ButtonVariant.contained
                                color = ButtonColor.success
                                disabled = isFetching
                                onClick = {
                                    mainScope.launch {
                                        isFetching = true
                                        val billExists = getBillExist()
                                        billExists?.let { billExists ->

                                            globalState.handleUpdateUserData(
                                                globalState.userData.copy(
                                                    onBoardingStatus = OnBoardingStatus.DONE
                                                )
                                            )

                                            if (billExists) {
                                                navigate.invoke(PageRoutes.CONTROL_CENTER.route)
                                            } else {
                                                navigate.invoke(PageRoutes.UTILITY_ACCOUNTS.route)
                                            }
                                        }

                                        isFetching = false
                                    }
                                }
                                if (isFetching) +"Redirecting..." else +"Go to portal"
                            }
                        }
                    }
                }
            }

        }
        // Onboarding Logic End

        // Page Logic Start
        if (isOnboardingCompleted(globalState.userData.onBoardingStatus)) {
            Box {
                sx {
                    marginBottom = 10.px
                    display = Display.flex
                    justifyContent = JustifyContent.spaceBetween
                }
                Button {
                    variant = ButtonVariant.contained
                    +PageTitles.UTILITY_BILLS.title
                    endIcon = endIcon.also {
                        KeyboardArrowRight()
                    }
                    onClick = {
                        navigate.invoke(PageRoutes.UTILITY_BILLS.route)
                    }
                }
                UtilityAccountSummaryFilterComponent {
                    currentFilterOptions = filters
                    currentFilterOptionsStateSetter = filtersStateSetter
                    this.facilityFilterOptions = facilityFilterOptions
                    this.cityFilterOptions = cityFilterOptions
                    this.stateFilterOptions = stateFilterOptions
                    this.zipcodeFilterOptions = zipcodeFilterOptions
                    this.streetFilterOptions = streetFilterOptions
                    filtersCount = activeFiltersCount
                }

            }
        }

        TableContainer {
            if (isOnboardingCompleted(globalState.userData.onBoardingStatus)) {
                className = ClassName("utility-account-summary-table-height")
            }
            Table {
                stickyHeader = true
                TableHead {
                    TableRow {
                        TableCell {
                            Tooltip {
                                title = if (expandAllRows) ReactNode("Collapse All") else ReactNode("Expand All")
                                IconButton {
                                    onClick = {
                                        expandAllRows = !expandAllRows
                                    }
                                    if (expandAllRows) {
                                        KeyboardArrowUp {}
                                    } else {
                                        KeyboardArrowDown {}
                                    }
                                }
                            }
                        }
                        for (header in utilityAccountSummaryHeadersList) {
                            TableHeader {
                                tableHeaderName = header
                            }
                        }
                    }
                }
                TableBody {
                    if (isLoading || facilityList.isEmpty()) {
                        TableCell {
                            colSpan = 5
                            align = TableCellAlign.center
                            if (isLoading) +"Fetching Data..." else +helperMessage
                        }
                    } else {
                        for (facilityData in facilityList) {
                            UtilityAccountSummaryTableRow {
                                expandRows = expandAllRows
                                facility = facilityData
                                this.setFetchOnboardingStatus = setFetchOnboardingStatus
                            }
                        }
                    }
                }
            }
        }
    }
}